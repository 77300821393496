import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatLegacySpinner as MatSpinner } from '@angular/material/legacy-progress-spinner';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { MessageService } from 'services/message/message.service';
import { environment } from '../environments/environment';
import { ddConfig } from 'utilities/environment';
import { Theme } from 'services/broadcast.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { SessionExpiredComponent } from './sessionexpired/session-expired.component';
import * as branch from 'branch-sdk';
import { AuthService, defined } from 'core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

  title = 'coinzoom';
  showHeaderFooter = false;
  simple = environment.simple;
  theme = this.simple ? Theme.day : (window.localStorage.getItem('themType') as Theme) || Theme.day;
  sentErrors = new Map<string, number>();

  constructor(
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly renderer: Renderer2,
    private readonly toastrService: ToastrService,
    private readonly modalService: BsModalService,
    private readonly authService: AuthService,
    overlay: Overlay
  ) {
    const spinner = overlay.create({
      hasBackdrop: true,
      positionStrategy: overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: overlay.scrollStrategies.block(),
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        spinner.attach(new ComponentPortal(MatSpinner));
      } else if (event instanceof NavigationEnd) {
        spinner.detach();
      } else if (event instanceof NavigationCancel) {
        spinner.detach();
      }
    });
  }

  ngOnInit() {
    this.messageService.notifications.subscribe((errorObj) => {
      if (errorObj.status === 401) {
        this.authService.clearAuthData();
        this.router.navigate(['/landing']).then((_) => this.showSessionExpired());
      }
    });

    if (environment.production) {
      this.initDataDog();
    }

    this.themeChange(this.theme);
    this.initToaster();
    this.initBranchIO();
  }

  private initBranchIO(): void {
    branch.init(environment.production ? 'key_live_akXDJrX1OfTMHlrUfYhn5ghlEtaKjpbP' : 'key_test_lb4wRxX9QiLSHoxLp1CozlodBEgMife9');
  }

  private initToaster(): void {
    this.toastrService.overlayContainer = this.toastContainer;
    this.toastrService.toastrConfig.positionClass = 'toast-right-here';
  }

  private initDataDog(): void {
    datadogRum.init({
      ...ddConfig(),
      clientToken: 'pub5617114d9d246d0d69e24b93ea364be8',
      applicationId: '5ad06470-4d8e-43b6-ab4f-518f41631773',
      allowedTracingOrigins: [/https:\/\/.*\.coinzoom(inc)?\.com$/],
      beforeSend: (event) => {
        if (event.type === 'error') {
          const message = event.error.message;
          const count = (this.sentErrors.get(message) ?? 0) + 1;
          this.sentErrors.set(message, count);

          if (!isWholeNumber(Math.log2(count))) {
            return false;
          }
          event.error.message = `${message} (repeated ${count})`;
        }
        return true;
      },
    });

    datadogLogs.init({
      ...ddConfig(),
      clientToken: 'pub5617114d9d246d0d69e24b93ea364be8',
      beforeSend: (log) => {
        const http = log.http;
        // Filter out logs related to trackers' requests
        return (
          !defined(http) || http.url.match(/coinzoom.com|app.link|api2.branch.io|cardinalcommerce.com|amazonaws.com|tabapay.com/) != null
        );
      },
    });
  }

  onActivate() {
    this.showHeaderFooter =
      !this.router.url.includes('/web-chart') && !this.router.url.includes('/chartpopout') && this.router.url !== '/404';
  }

  themeChange(theme: Theme) {
    window.localStorage.setItem('themType', theme);
    this.theme = theme;
    if (theme === Theme.night) {
      this.renderer.addClass(document.body, 'themeNight');
    } else {
      this.renderer.removeClass(document.body, 'themeNight');
    }
  }

  private showSessionExpired(): void {
    if (!SessionExpiredComponent.isShown) {
      this.modalService.show(SessionExpiredComponent, {
        class: 'modal-sm',
      });
    }
  }
}

// checks there's no remainder after division by 1
const isWholeNumber = (x: number) => x % 1 === 0;
