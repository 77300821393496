import BigNumber from 'bignumber.js';
import * as R from 'runtypes';

import { Enum } from '../utils/runtypes';
import { Currency } from './currency';

export const ApiWallet = R.Record({
  currencyCd: R.String,
  networks: R.Array(R.String),
  total: R.String,
  available: R.String,
  availableUsd: R.String.nullable(),
  pending: R.String,
  staked: R.String,
  frozen: R.String,
  lockedAmount: R.String,
}).asReadonly();

export enum VaultStatus {
  CREATED = 'CREATED',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const ApiVault = R.Record({
  assets: R.Array(ApiWallet).asReadonly(),
  status: Enum(VaultStatus),
}).asReadonly();

export enum TransactionStatusSummary {
  SUCCEEDED = 'SUCCEEDED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
}

export enum TransactionStatus {
  SUBMITTED = 'SUBMITTED',
  QUEUED = 'QUEUED',
  PENDING_AUTHORIZATION = 'PENDING_AUTHORIZATION',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  BROADCASTING = 'BROADCASTING',
  PENDING_3RD_PARTY_MANUAL_APPROVAL = 'PENDING_3RD_PARTY_MANUAL_APPROVAL',
  PENDING_3RD_PARTY = 'PENDING_3RD_PARTY',
  CONFIRMING = 'CONFIRMING',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  PENDING_AML_SCREENING = 'PENDING_AML_SCREENING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
}

export enum TransactionEndType {
  VAULT_ACCOUNT = 'VAULT_ACCOUNT',
  EXCHANGE_ACCOUNT = 'EXCHANGE_ACCOUNT',
  INTERNAL_WALLET = 'INTERNAL_WALLET',
  EXTERNAL_WALLET = 'EXTERNAL_WALLET',
  ONE_TIME_ADDRESS = 'ONE_TIME_ADDRESS',
  NETWORK_CONNECTION = 'NETWORK_CONNECTION',
  FIAT_ACCOUNT = 'FIAT_ACCOUNT',
  COMPOUND = 'COMPOUND',
  UNKNOWN = 'UNKNOWN',
}

export const ApiTransactionEnd = R.Record({
  type: Enum(TransactionEndType),
  id: R.String.nullable().optional(),
  name: R.String.nullable().optional(),
  subType: R.String.nullable().optional(),
}).asReadonly();

export const ApiTransaction = R.Record({
  currencyCd: R.String,
  network: R.String.nullable(),
  feeCurrencyCd: R.String,
  amount: R.Number,
  amountUsd: R.Number.nullable(),
  fee: R.Number,
  feeUsd: R.Number.nullable(),
  status: Enum(TransactionStatus),
  createdAt: R.String,
  lastUpdatedAt: R.String,
  source: ApiTransactionEnd,
  destination: ApiTransactionEnd,
  sourceAddress: R.String,
  destinationAddress: R.String,
  destinationAddressDescription: R.String,
  destinationTag: R.String,
  txHash: R.String,
  note: R.String.nullable(),
}).asReadonly();

export const ApiTransactionsResponse = R.Record({
  transactions: R.Array(ApiTransaction).asReadonly(),
  next: R.String.nullable(),
  previous: R.String.nullable(),
}).asReadonly();

export type ApiVault = R.Static<typeof ApiVault>;
export type ApiWallet = R.Static<typeof ApiWallet>;
export type ApiTransactionEnd = R.Static<typeof ApiTransactionEnd>;
export type ApiTransaction = R.Static<typeof ApiTransaction>;
export type ApiTransactionsResponse = R.Static<typeof ApiTransactionsResponse>;

export type ChilledWallet = {
  available: BigNumber;
  availableUsd: BigNumber | null;
  currency: Currency;
  networks: Array<string>;
  walletExplorerUrls: Map<string, string | null>;
  txnExplorerUrls: Map<string, string | null>;
};
