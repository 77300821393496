import {
  baseUrl,
  baseAuthUrl,
  baseNotificationUrl,
  baseConfigUrl,
  baseCryptoUrl,
  basePartnerPaymentUrl,
  BaseURLS,
  baseOrderUrl,
  baseHistoryUrl,
} from 'core';

const baseApiKeysUrl = baseUrl + '/apikeys-api';
const baseAccountUrl = baseUrl + '/account-api';
const baseBankUrl = baseUrl + '/bank-api';
const baseWireUrl = baseUrl + '/wire-api';
const baseAchUrl = baseUrl + '/ach-api';
const baseGalileoCardUrl = baseUrl + '/galileo';
const baseSystemNotificationsUrl = baseUrl + '/system-notification-api';
const baseStatementUrl = baseUrl + '/statement-api';
const baseReferralUrl = baseUrl + '/referral-api';
const baseTaxUrl = baseUrl + '/tax-api';
const baseMarginUrl = baseUrl + '/margin-api';
const baseChilledVaultUrl = baseUrl + '/chilled-api';

export const URLS = {
  ...BaseURLS,
  forgotPasswordEmailSend: baseAuthUrl + '/forgotpassword?email=',
  forgotPasswordOTP: baseAuthUrl + '/match/reset_password/otp',
  forgetPassword: baseAuthUrl + '/reset/password',
  registerLinkVerify: baseAuthUrl + '/verify?token=',
  googleAuthAPI: baseAuthUrl + '/generate_auth_key',
  acceptTermsAndConditions: baseAuthUrl + '/accept/ts_and_cs',
  sendwallet: baseCryptoUrl + '/send',
  cancelOrder: baseOrderUrl + '/cancel',
  changesPasswordApi: baseAuthUrl + '/change_password',
  userNotification: baseNotificationUrl + '/notification/settings',
  shareReferral: baseAuthUrl + '/referral?emailId=',
  getReferral: baseAuthUrl + '/referral/history',
  resendLinkVerify: baseAuthUrl + '/resend/email_verfication_link',
  emailResend: baseAuthUrl + '/resend/email_verfication_link',
  personalizedSettings: baseAuthUrl + '/user/',
  getTradeConfig: baseAuthUrl + '/user_settings/two_factor',
  getTransactionConfig: baseAuthUrl + '/withdraw_two_factor',
  getAdvanceConfig: baseAuthUrl + '/two_factor_settings',
  getReferralUrl: baseAuthUrl + '/referral/url',
  getExternalIdentifiers: baseAuthUrl + '/external/link/list',
  saveExternalIdentifier: baseAuthUrl + '/external/link/save',
  getReferralSettings: baseReferralUrl + '/referral_program',
  listWireBankDetails: baseWireUrl + '/banks/list',
  listAchBankDetails: baseAchUrl + '/banks/list',
  submitAchWithdrawal: baseAchUrl + '/withdrawal',
  achMetadataUrl: baseAchUrl + '/metadata',
  listBankDetails: baseBankUrl + '/list',
  saveBankDetails: baseBankUrl + '/save',
  removeBankDetails: baseBankUrl + '/delete',
  wirewithdrawalUrl: baseWireUrl + '/withdraw',
  wireDepositInstructionsUrl: baseWireUrl + '/instructions',
  checkAddressType: baseCryptoUrl + '/address/{ccy}/{address}/type',
  zoommeHandleUrl: baseCryptoUrl + '/zoomme/details/{handle}',
  getAllSystemNotifications: baseSystemNotificationsUrl + '/list/active',

  // ============================= CZ PRIME =========================
  candleHistoryUrl: baseHistoryUrl + '/candles',
  primeMetadataUrl: baseConfigUrl + '/prime/metadata',
  kycLimitsUrl: baseCryptoUrl + '/kyc_limits',
  ledgerEntryTypesURl: baseConfigUrl + '/ledger_entry_types',
  orderHistoryUrl: baseHistoryUrl + '/orders',
  orderUrl: baseHistoryUrl + '/order/',
  transactionHistoryUrl: baseHistoryUrl + '/ledger_entries',
  transactionHashUrl: (entryType: string, entryRef: string) => baseHistoryUrl + `/ledgers_entry_hash/${entryType}/${entryRef}`,
  userExchangeBlock: baseAuthUrl + '/user_exchange',
  loginHistory: baseNotificationUrl + '/user/activity_log',
  listApiKeysUrl: baseApiKeysUrl + '/list',
  generateApiKeyUrl: baseApiKeysUrl + '/generate',
  disableApiKeyUrl: baseApiKeysUrl + '/disable',
  saveApiKeyUrl: baseApiKeysUrl + '/save',
  getWhiteListAddress: baseAccountUrl + '/payee/list/',
  verifyWalletAddress: baseCryptoUrl + '/address/{ccy}/{address}/verify',
  saveWhiteListAddress: baseAccountUrl + '/payee/save',
  deleteWhiteListAddress: baseAccountUrl + '/payee/delete',
  getCardImageUrl: baseGalileoCardUrl + '/get_card_image',
  cardSpendCurrencyUrl: baseGalileoCardUrl + '/spend/currency',
  orderCardUrl: baseGalileoCardUrl + '/request_physical_card',
  physicalCardStatusUrl: baseGalileoCardUrl + '/get_physical_card_status',
  activateCardUrl: baseGalileoCardUrl + '/activate_physical_card',
  getCardPinUrl: baseGalileoCardUrl + '/pin_change_key',
  commitCardPinUrl: baseGalileoCardUrl + '/commit_pin_change',
  shipCardUrl: baseGalileoCardUrl + '/ship_physical_card',
  cardUpgradeStatusUrl: baseGalileoCardUrl + '/card_upgrade_status',
  cardPaymentsUrl: baseGalileoCardUrl + '/get_card_payments',
  replaceLostCardUrl: baseGalileoCardUrl + '/replace_lost_physical_card',
  freezeCardUrl: baseGalileoCardUrl + '/freeze_physical_card',
  unfreezeCardUrl: baseGalileoCardUrl + '/unfreeze_physical_card',
  holdFundsUrl: baseUrl + '/onholdfunds-api/list',
  checkProfileSubmittedUrl: baseAuthUrl + '/user/application_settings',
  checkCardAvailable: baseGalileoCardUrl + '/card_available',

  // Statements
  getStatement: baseStatementUrl + '/get',
  listStatements: baseStatementUrl + '/list',

  // Tax
  getTaxTranactions: baseTaxUrl + '/transactions?start={start}&end={end}',

  // Payments
  getRecurringPayments: basePartnerPaymentUrl + '/list/recurring/payments',
  getRecurringPaymentInstances: basePartnerPaymentUrl + '/list/recurring/instances',
  hasRecurringPayments: basePartnerPaymentUrl + '/has/recurring/payments',
  cancelRecurringPayment: basePartnerPaymentUrl + '/cancel/recurring/payment/',
  approveRecurringPriceChange: basePartnerPaymentUrl + '/approve/recurring/payment/change/',

  // Direct deposit
  directDepositAccount: baseBankUrl + '/direct-deposit-account',
  directDeposit: baseBankUrl + '/direct-deposit',

  // Margin trading
  transferCollateral: baseMarginUrl + '/collateral/transfer',
  marginSummary: baseMarginUrl + '/summary',
  historicPositions: baseMarginUrl + '/historic',
  liquidate: baseMarginUrl + '/liquidate',
  addPositionProtection: baseMarginUrl + '/add/protection',

  // Chilled Vault
  chilledVault: baseChilledVaultUrl + '/vault',
  chilledVaultSupportedAssets: baseChilledVaultUrl + '/supported_assets',
  chilledVaultCreateWallet: (asset: string, network: string) => `${baseChilledVaultUrl}/vault/${asset}/${network}`,
  chilledVaultWalletAddresses: (asset: string, network: string) => `${baseChilledVaultUrl}/vault/${asset}/${network}/addresses`,
  chilledVaultRedeem: `${baseChilledVaultUrl}/transfer`,
  chilledVaultTransactions: baseChilledVaultUrl + '/transactions',
};
